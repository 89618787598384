import logo from './assets/images/UseCivitaWhite.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <img className='App-logo' src={logo} alt='use civitá' />
      <h1 style={{color:'white'}}>Em manutenção</h1>
    </div>
  );
}

export default App;
